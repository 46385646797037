"use client";

import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { OutlinedButton } from "@/components/button/outlined-button";
import { Heading } from "@/components/heading";
import { StoreLocatorSearchFormFrame } from "@/components/store-locator/form/store-locator-search-form-frame";
import { StoreLocatorStandaloneForm } from "@/components/store-locator/form/store-locator-standalone-form";
import { StoreLocatorUtils } from "@/utils/store-locator-utils";
import { StoreLocatorProvider } from "@/utils/stores/use-store-locator";
import Link from "next/link";
import { type Metadata } from "next";
import { Section } from "@/components/sections/section";
import { MainLayout } from "./main-layout";

export const metadata: Metadata = {
  title: "Erreur interne"
}

export default function ErrorPage({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error);
  }, [error]);

  return (
    <MainLayout>
      <StoreLocatorProvider>

        <Section
          className="flex min-h-[80vh] flex-col items-center justify-center py-12 text-center" fullWidth>
          <div>
            <Heading level={2} as="h1" className="mt-8">
              Erreur
            </Heading>

            <p>Désolé, il semblerait qu&apos;une erreur inattendue se soit produite.</p>
          </div>

          <StoreLocatorSearchFormFrame className="mx-auto max-w-lg">
            <StoreLocatorStandaloneForm />
          </StoreLocatorSearchFormFrame>

          <Link href={StoreLocatorUtils.buildUrl()} className="block">
            <OutlinedButton>Réservez votre box</OutlinedButton>
          </Link>
        </Section>
      </StoreLocatorProvider>
    </MainLayout>
  );
}
