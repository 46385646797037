import {type FC, memo, type ReactNode} from "react";
import Link from "next/link";

type Props = {
  url: string,
  children: ReactNode,
}

const FooterLinkItem:FC<Props> = memo(function ({url, children}) {
  return <Link href={url} className={"transition-colors hover:text-brand"}>
    {children}
  </Link>
});

FooterLinkItem.displayName = 'FooterLinkItem';
export { FooterLinkItem };
