import { type FC } from 'react';
import { FooterLinkItem } from './footer-link-item';
import { Heading } from '../heading';
import Link from 'next/link';
import styles from './footer.module.scss';
import { Logo } from '../brand/logo';
import { LogoCapitalBestCompany } from '../brand/logo-capital-best-company';
import { OutlinedButton } from '../button/outlined-button';
import { StoreLocatorUtils } from '@/utils/store-locator-utils';
import { YouTubeEmbed } from '@next/third-parties/google';

type Props = {
  className?: string;
};

export const Footer: FC<Props> = function ({ className = '' }) {
  const fullYear = new Date().getFullYear();

  return (
    <footer className={`overflow-hidden bg-white ${className}`}>
      {/* Black footer */}
      <div
        className={`bg-brand-black py-20 text-sm text-white ${styles.blackFooter}`}>
        <div
          className={
            'container mx-auto grid gap-16 px-4 md:grid-cols-2 md:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1'
          }>
          {/* First column */}
          <section className={'mx-auto w-full max-w-[300px] space-y-8'}>
            <Logo />

            <div className={'rounded-lg bg-white p-4'}>
              <LogoCapitalBestCompany />
            </div>

            <ul className={'space-y-4'}>
              <li>Boxes de stockage à louer à prix discount.</li>
              <li>Garde-meuble, archivage, entreposage</li>
            </ul>
          </section>

          {/* Second column */}
          <section className={'w-full space-y-6'}>
            <Heading as={'div'} level={3} textColor={'brand'}>
              Accès directs
            </Heading>

            <nav className={'flex flex-col gap-6'}>
              <FooterLinkItem url={'/articles/qui-sommes-nous/'}>
                Qui sommes-nous ?
              </FooterLinkItem>
              <FooterLinkItem url={'/articles/garde-meuble/'}>
                Garde-meuble en libre-service
              </FooterLinkItem>
              <FooterLinkItem url={'/articles/boxes-de-stockage-a-louer/'}>
                Boxes à louer en France
              </FooterLinkItem>
              <FooterLinkItem url={'/articles/faqs-de-loffre-selfstock-com/'}>
                FAQs de l&apos;offre selfstock.com
              </FooterLinkItem>
              <FooterLinkItem url={StoreLocatorUtils.buildUrl()}>
                Découvrez nos centres de stockage
              </FooterLinkItem>
              <FooterLinkItem url={'https://franchise.selfstock.com/'}>
                Devenir franchisé
              </FooterLinkItem>
              <FooterLinkItem url={'/articles/contactez-nous/'}>
                Contactez-nous
              </FooterLinkItem>
            </nav>
          </section>

          {/* Third column */}
          <section className={'w-full space-y-12'}>
            <div className="space-y-6">
              <Heading
                as={'div'}
                level={3}
                textColor={'brand'}
                className={'mb-6'}>
                Location instantanée
              </Heading>

              <p>
                Réservez votre box en quelques clics sur notre site internet.
              </p>

              <p>
                Sans rendez-vous, sans état des lieux, dans 2 minutes vous
                stockerez vos affaires dans un de nos boxes.
              </p>

              <Link href={StoreLocatorUtils.buildUrl()} className="block">
                <OutlinedButton
                  background={'brand-black'}
                  color={'white'}
                  block>
                  Voir les tarifs et dispos
                </OutlinedButton>
              </Link>
            </div>

            <div className="space-y-6">
              <Heading
                as={'div'}
                level={3}
                textColor={'brand'}
                className={'mb-6'}>
                Guides pratiques
              </Heading>

              <p>
                Tous{' '}
                <Link href={'/articles/conseils-demenagement/'}>
                  nos conseils
                </Link>{' '}
                sur la bonne gestion d&apos;un{' '}
                <Link href={'/articles/demenagement/'}>déménagement</Link>
              </p>

              <p>
                Tous{' '}
                <Link href={'/articles/conseils-gain-plance/'}>
                  nos conseils
                </Link>{' '}
                si vous avez besoin de{' '}
                <Link href={'/articles/comment-gagner-place/'}>
                  gagner de la place
                </Link>{' '}
                chez vous !
              </p>
            </div>
          </section>

          {/* Fourth column */}
          <section className="w-full space-y-6">
            <Heading
              as={'div'}
              level={3}
              textColor={'brand'}
              className={'mb-6'}>
              Le concept
            </Heading>

            <div className="overflow-hidden rounded-xl">
              <div className={'grid aspect-video w-full'}>
                <YouTubeEmbed 
                  videoid="Zwmq-kUjdBk"
                  playlabel={'Vidéo du concept selfstock.com'}
                  params={'rel=0'}
                />
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* White footer */}
      <div
        className={
          'container mx-auto grid gap-8 px-4 py-8 text-center font-semibold lg:grid-cols-2 lg:text-left'
        }>
        <div>© selfstock.com {fullYear}, tous droits réservés</div>

        <div
          className={
            'flex flex-col justify-end space-y-6 lg:flex-row lg:space-x-4 lg:space-y-0'
          }>
          <Link href={'/mentions-legales'}>Mentions légales</Link>

          <Link href={'/mentions-legales/declaration-de-confidentialite'}>
            Politique de confidentialité
          </Link>
        </div>
      </div>
    </footer>
  );
};
