import { type FC, type ReactNode } from 'react';
import { MainMenu } from '@/components/menu/main-menu';
import { CartProvider } from '@/components/cart/cart-provider';
import { Footer } from '@/components/footer/footer';
import { ZodI18n } from '@/components/zod-i18n';
import { GoogleTagManager } from '@next/third-parties/google';
import { Toaster } from "react-hot-toast";
import Script from 'next/script';

type Props = {
  children: ReactNode;
};

export const MainLayout: FC<Props> = function ({ children }) {
  return (
    <body className="main-menu-offset" suppressHydrationWarning>
    
      {/* Google Tag Manager GTM */}
      {process.env.NEXT_PUBLIC_API_URL === 'https://apiv3.selfstock.com/' && (
        <GoogleTagManager gtmId="GTM-T3WHJPDR" />
       )}

      <ZodI18n />

      <CartProvider>
        <MainMenu />
        {children}
        <Footer />
      </CartProvider>

      <Toaster
        position="bottom-left"  
      />

    <Script id="google-maps" async>
      {'(g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({ key: "' + process.env.NEXT_PUBLIC_GMAPS_API_KEY + '", v: "weekly",})'}
    </Script>
    </body>
  );
};
