import { Navbar } from "./navbar";
import { type FC } from "react";

const MainMenu: FC = function() {
  return <>
    <Navbar id="main-menu-container" />
  </>;
};

MainMenu.displayName = "MainMenu";
export {MainMenu};